import axios from 'axios'

export default {
  fetchMetricsByDate (period, periodId) {
    return axios.get('metrics?period=' + period + '&period_id=' + periodId).then(response => {
      return response.data
    })
  },

  fetchMetricsByDateAndType (period, periodId, typeId) {
    return axios.get('metrics?period=' + period + '&type_id=' + typeId + '&period_id=' + periodId).then(response => {
      return response.data
    })
  },

  fetchChartData (typeId) {
    return axios.get('metrics/chartdata?type_id=' + typeId).then(response => {
      return response.data
    })
  },
}
