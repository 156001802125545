<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="daily"
        cols="12"
        lg="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          id="chart-daily"
          color="primary"
          icon="mdi-chart-timeline-variant"
        >
          <template v-slot:after-heading>
            <div class="display-1 mt-2 font-weight-light">
              {{ metricType.name }}
            </div>
          </template>
          <chartist
            :data="daily"
            :options="options"
            type="Line"
            style="max-height: 250px;"
            class="mt-3"
          />

          <v-divider class="ma-3" />

          <div class="px-3">
            <v-row
              align="center"
              class="ma-0"
            >
              <v-avatar
                class="mr-1"
                color="primary"
                size="12"
              />

              <span class="mr-3 font-weight-light">Текущее значение</span>

              <v-avatar
                class="mr-1"
                color="rgba(83, 18, 42, 0.2)"
                size="12"
              />

              <span class="mr-3 font-weight-light">30 днями ранее</span>
            </v-row>
          </div>
          <v-divider
            v-if="metricType.description"
            class="ma-3"
          />
          <div
            v-if="metricType.description"
            class="px-3 text--disabled"
          >
            <v-row
              align="center"
              class="ma-0"
            >
              {{ metricType.description }}
            </v-row>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="weekly"
        cols="12"
        lg="6"
      >
        <base-material-card
          id="chart-weekly"
          color="info"
          icon="mdi-chart-timeline-variant"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light mt-2">
              По неделям
            </div>
          </template>

          <chartist
            :data="weekly"
            :options="optionsWeek"
            type="Bar"
            style="max-height: 250px;"
            class="mt-3"
          />
        </base-material-card>
      </v-col>

      <v-col
        v-if="monthly"
        cols="12"
        lg="6"
      >
        <base-material-card
          id="chart-monthly"
          color="success"
          icon="mdi-poll-box"
        >
          <template v-slot:after-heading>
            <div class="display-1 mt-2 font-weight-light">
              По месяцам
            </div>
          </template>

          <chartist
            :data="monthly"
            :options="optionsWeek"
            type="Bar"
            style="max-height: 250px;"
            class="mt-3"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import metricsApi from '../services/MetricsApi'
  import Chartist from 'chartist'
  import * as ChartistTooltips from 'chartist-plugin-tooltips-updated'
  import moment from 'moment'

  export default {
    props: ['id'],
    data () {
      return {
        daily: {},
        weekly: {},
        monthly: {},
        metricType: { name: null, description: null },
        options: {
          plugins: [Chartist.plugins.tooltip()],
          chartPadding: {
            top: 15,
            right: 15,
            bottom: 35,
            left: 30,
          },
        },
        optionsWeek: {
          plugins: [Chartist.plugins.tooltip({ pointClass: 'ct-bar' })],
          chartPadding: {
            top: 15,
            right: 15,
            bottom: 75,
            left: 30,
          },
        },
        error: null,
      }
    },
    mounted () {
      document.title = 'Метрика #' + this.id + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      metricsApi
        .fetchChartData(this.id)
        .then(response => {
          if (response) {
            this.metricType = response.metricType
            document.title = this.metricType.name + ' | ' + process.env.VUE_APP_NAME
            this.daily = response.daily
            for (var i = 0; i < this.daily.labels.length; i++) {
              let date = moment(this.daily.labels[i], 'gggg-MM-DD')
              this.daily.labels[i] = date.format('DD.MM.gggg')
            }
            this.weekly = response.weekly
            for (i = 0; i < this.weekly.labels.length; i++) {
              let date = moment(this.weekly.labels[i], 'gggg-ww')
              this.weekly.labels[i] = date.format('DD MMM') + '-' + date.add(1, 'weeks').add(-1, 'days').format('DD MMM')
            }
            this.monthly = response.monthly
            for (i = 0; i < this.monthly.labels.length; i++) {
              let date = moment(this.monthly.labels[i], 'gggg-MM')
              this.monthly.labels[i] = date.format('MMMM gggg')
            }
          }
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
          this.loading = false
        })
    },
    methods: {
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
    },
  }
</script>

<style lang="sass">
  #chart-daily
    .ct-series-a .ct-line,
    .ct-series-a .ct-point
      stroke: #E91E63 !important

    .ct-series-b .ct-line,
    .ct-series-b .ct-point
      stroke: rgba(83, 18, 42, 0.2) !important

  #chart-weekly
    .ct-series-a .ct-bar
      stroke: #00cae3 !important

  #chart-monthly
    .ct-series-a .ct-bar
      stroke: #00cae3 !important

  .ct-label.ct-label.ct-horizontal.ct-end
    position: relative
    justify-content: flex-end
    text-align: right
    transform-origin: 100% 0
    transform: translate(-100%) rotate(-45deg)
    white-space: nowrap
</style>

<style>
  .chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 5em;
    padding: .5em;
    background: #E91E63;
    color: white;
    font-family: Oxygen,Helvetica,Arial,sans-serif;
    font-weight: 700;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: opacity .2s linear;
    -moz-transition: opacity .2s linear;
    -o-transition: opacity .2s linear;
    transition: opacity .2s linear; }
  .chartist-tooltip:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top-color: #E91E63; }
  .chartist-tooltip.tooltip-show {
    opacity: 1; }

  .ct-area, .ct-line {
    pointer-events: none; }
</style>
